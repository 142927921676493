.hero {
    background: url("../../assets/bg1234.png") center center no-repeat;
    background-size: cover; /* Ensures the background image covers the entire hero section */
    color: white;
    text-align: center;
    padding: 80px 20px; /* Default padding */
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh; /* Default min-height */
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .hero-content {
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for text readability */
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .hero h1 {
    font-size: 2.5rem; /* Default font size for larger screens */
    font-family: 'ethnocentric', sans-serif; /* Apply the desired font */
    margin-bottom: 20px;
    line-height: 1.2; /* Adjust line-height for readability */
  }
  
  .hero p {
    font-size: 1.2rem; /* Default font size for larger screens */
    margin-bottom: 30px;
    line-height: 1.4;
  }
  
  .cta-button {
    background: #dabd18;
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 5px;
  }
  
  .cta-button:hover {
    background: #bfa617;
  }
  
  /* Responsive Styles */
  
  /* For Large Tablets and Smaller Laptops */
  @media (max-width: 1024px) {
    .hero {
      padding: 60px 15px;
      min-height: 50vh;
    }
  
    .hero h1 {
      font-size: 2rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .cta-button {
      padding: 8px 24px;
      font-size: 0.9rem;
    }
  }
  
  /* For Mobile Phones */
  @media (max-width: 430px) {
    .hero {
      padding: 40px 10px;
      min-height: 50vh;
    }
  
    .hero h1 {
      font-size: 1.5rem;
      line-height: 1.3; /* Adjust line-height for better readability */
    }
  
    .hero p {
      font-size: 1rem;
      line-height: 1.4; /* Ensure spacing is readable */
    }
  
    .hero-content {
      padding: 15px;
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .cta-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
  
  /* For Small Mobile Phones (i.e., older iPhones) */
  @media (max-width: 320px) {
    .hero h1 {
      font-size: 1.2rem;
    }
  
    .hero p {
      font-size: 0.9rem;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 0.8rem;
    }
  }
  