/* Apply global font styles */
body,
* {
  font-family: "ethnocentric", sans-serif;
  font-weight: 700;
  font-style: italic;
}
/* Header styling */
.header {
  background: #5f7a9c;
  padding: 10px 0;
  color: #122b4c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 75px;
  height: 100px;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.cta-button {
  background: #dabd18;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  height: 4px;
  width: 25px;
  background-color: white;
  margin: 3px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-open .nav-links {
    display: flex;
  }

  .hamburger {
    display: flex;
  }
}


/* Header styling */
.header {
  background: #5f7a9c;
  padding: 10px 0;
  color: #122b4c;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.logo {
  width: 75px;
  height: 100px;
  background-image: url(./assets/logo.png);
  background-size: contain;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.cta-button {
  background: #dabd18;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Hero section styling */
.hero {
  background: url("../src/assets/bg1234.png") center;
  background-size: 60%;
  color: black;
  text-align: center;
  padding: 100px 0;
  position: relative;
}

.hero h1,
.hero p {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  margin: 10px 0;
}

.hero button {
  background-color: #d4af37;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hero button:hover {
  background-color: #c19430;
}

/* Section styling */
.about-us,
.services,
.portfolio,
.testimonials,
.blog,
.contact {
  padding: 50px 0;
  background-color: #122b4c;
  color: white;
}

.service-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service-card {
  width: 30%;
  background: #dabd18;
  padding: 20px;
  text-align: center;
  border: 2px solid #008080;
  margin-bottom: 20px;
}

/* Footer styling */
.footer {
  background: #1e3a5f;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  border: 2px solid #008080;
}

.footer-links a:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.social-media {
  text-align: center;
  margin: 20px 0;
}

.newsletter-signup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.newsletter-signup input {
  padding: 10px;
  margin-right: 10px;
}

.newsletter-signup button {
  padding: 10px;
  background: #008080;
  color: white;
  border: none;
}

/* Padding for text elements */
.about-us p,
.services p,
.portfolio p,
.testimonials p,
.blog p,
.contact p,
.about-us h1,
.services h1,
.portfolio h1,
.testimonials h1,
.blog h1,
.contact h1,
.about-us h2,
.services h2,
.portfolio h2,
.testimonials h2,
.blog h2,
.contact h2,
.about-us h3,
.services h3,
.portfolio h3,
.testimonials h3,
.blog h3,
.contact h3,
.about-us h4,
.services h4,
.portfolio h4,
.testimonials h4,
.blog h4,
.contact h4,
.about-us h5,
.services h5,
.portfolio h5,
.testimonials h5,
.blog h5,
.contact h5,
.about-us h6,
.services h6,
.portfolio h6,
.testimonials h6,
.blog h6,
.contact h6 {
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7);
}

/* Responsive CSS */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .hero {
    padding: 50px 0;
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links li {
    margin: 10px 0;
  }

  .footer-links a {
    margin: 5px 0;
  }

  .newsletter-signup {
    flex-direction: column;
    align-items: center;
  }

  .newsletter-signup input,
  .newsletter-signup button {
    width: 80%;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .hero h1,
  .hero p {
    padding: 5px 10px;
    font-size: 1rem;
  }

  .hero {
    padding: 30px 0;
  }

  .service-card {
    width: 90%;
  }

  .footer-links li {
    margin: 10px 0;
  }

  .footer-links a {
    margin: 5px 0;
  }

  .newsletter-signup input,
  .newsletter-signup button {
    width: 90%;
  }
}
/* Header styling */
.header {
  background: #5f7a9c;
  padding: 10px 0;
  color: #122b4c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 75px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.cta-button {
  background: #dabd18;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 4px;
  background-color: rgb(49, 62, 82);
  margin: 3px 0;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}
footer {
  background-color: #122b4c; /* Dark background for contrast */
  color: white;
  padding: 20px 0;
  text-align: center;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.social-icon {
  font-size: 24px;
  color: white;
  margin: 0 10px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.social-icon:hover {
  color: #dabd18; /* Change color on hover */
}

.newsletter-signup {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-signup input {
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  width: 250px;
}

.newsletter-signup button {
  padding: 10px 20px;
  background-color: #dabd18;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-signup button:hover {
  background-color: #bfa617;
}

p {
  margin-top: 20px;
  font-size: 14px;
}
.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: background-color 0.3s ease;
  border-radius: 50%; /* Optional: Rounded button */
}

.toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
}

.toggle-button svg {
  width: 28px; /* Ensures the icon size is consistent */
  height: 28px;
  stroke: white; /* Color matches site theme */
  transition: stroke 0.3s ease;
}

@media (max-width: 768px) {
  .toggle-button {
    /* Ensures visibility and usability on smaller screens */
    padding: 8px;
  }

  .toggle-button svg {
    width: 24px; /* Smaller size for mobile */
    height: 24px;
  }
}
