.header {
    background: #5f7a9c;
    padding: 10px 20px;
    color: #122b4c;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .left-section {
    display: flex;
    align-items: center;
  }
  
  .right-section {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .logo h1 {
    margin-left: 15px;
    color: white;
    font-size: 24px;
    font-family: 'ethnocentric', sans-serif; /* Apply the desired font */
  }
  
  .cta-button {
    background: #dabd18;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 16px;
    border-radius: 4px;
    margin-left: 20px;
  }
  
  .cta-button:hover {
    background: #bfa617;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    cursor: pointer;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 4px;
    background-color: white;
    margin: 3px 0;
  }
  
  .hamburger.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .logo h1 {
      font-size: 20px;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .container {
      padding: 0 10px;
    }
  }
  
  @media (max-width: 480px) {
    .logo h1 {
      font-size: 18px;
      margin-left: 10px;
    }
  
    .cta-button {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
  