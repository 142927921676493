/* Basic sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hidden by default */
    width: 250px;
    height: 100%;
    background: linear-gradient(to bottom right, #5f7a9c, #122b4c); /* Linear gradient */
    color: rgb(49, 62, 82);
    transition: left 0.3s ease; /* Smooth transition */
    z-index: 1000; /* Ensure it stays above other content when opened */
    overflow-y: auto; /* Allows scrolling if content overflows */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  }
  
  .sidebar.open {
    left: 0; /* Slide in from the left when open */
  }
  
  .sidebar-header {
    padding: 15px;
    text-align: right;
    background: rgba(0, 0, 0, 0.1); /* Optional background for header */
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .sidebar-nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-nav li {
    padding: 15px 20px;
    transition: background 0.3s ease;
  }
  
  .sidebar-nav li:hover {
    background: rgba(255, 255, 255, 0.1); /* Hover effect for better UX */
  }
  
  .sidebar-nav li a {
    color: #122b4c;
    text-decoration: none;
    display: block;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .sidebar {
      width: 25%; /* Expand width for mobile */
    }
  
    .sidebar-nav li {
      padding: 10px 15px;
    }
  }
  